import axios from 'axios';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { loadable } from 'jotai/utils';
import Router from 'next/router';
import { perfilAtom } from 'src/atoms/auth';
import Firebase from '../services/firebase/Firebase';
import { useSWRConfig } from 'swr';
import { useMemo } from 'react';

export const apiBaseUrl = (process.env.NEXT_PUBLIC_API_BASE ?? '').replace(/\/$/, '');

const formatAuthUser = (user, token, eu) => ({
  ...eu,
  token: token,
  uid: user.uid,
  email: user.email,
  providers: user.providerData.map((i) => i.providerId),
});

const googleProvider = new Firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const signInWithGoogle = () => Firebase.auth().signInWithPopup(googleProvider);

const linkEmailProvider = (email, password) => {
  return reauthenticateUser(null, 'google.com')
    .then((usercred) => {
      let emailCredential = Firebase.auth.EmailAuthProvider.credential(email, password);
      return usercred.user.linkWithCredential(emailCredential);
    })
    .catch((error) => {
      console.log(error);
    });
};

const unlinkAuthProvider = (providerId) => Firebase.auth().currentUser.unlink(providerId);

const updateCurrentUserPassword = (currentPassword, newPassword) => {
  return reauthenticateUser(currentPassword)
    .then((usercred) => {
      return usercred.user.updatePassword(newPassword);
    })
    .catch((error) => {
      console.log(error);
    });
};

const reauthenticateUser = (currentPassword = null, provider = 'password') => {
  let user = Firebase.auth().currentUser;
  if (['password', 'email'].indexOf(provider) >= 0) {
    if (!currentPassword) throw 'Password required for re-auth';
    let cred = Firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  } else if (['google.com', 'google'].indexOf(provider) >= 0) {
    return user.reauthenticateWithPopup(googleProvider);
  }
};

const signInWithEmailAndPassword = (email, password) => Firebase.auth().signInWithEmailAndPassword(email, password);

const createUserWithEmailAndPassword = (email, password) =>
  Firebase.auth().createUserWithEmailAndPassword(email, password);

const sendPasswordResetEmail = (email, continueUrl = null) => {
  const firebaseActionCodeSettings = {
    url: continueUrl ?? (typeof window !== 'undefined' ? window.location.origin + '/login' : ''),
  };
  Firebase.auth().sendPasswordResetEmail(email, firebaseActionCodeSettings);
};

const signOut = () => Firebase.auth().signOut();

const handleLogout = () => {
  signOut();
  const query = window.location?.href?.split('?')?.[1];
  const isSignup = window.location?.href?.includes('/router/account/personal/accountpersonal');

  if (isSignup) return;

  if (query) {
    Router.push(`/login?${query}`);
  }

  return Router.push('/login');
};

const permissionInterceptor = (err) => {
  if (err.response?.status === 401) {
    handleLogout();
  } else if (err.response?.status === 403) {
    Router.push('/permission-error');
  }

  return Promise.reject(err);
};

// Atoms
const firebaseUserAtom = atom('initial');

firebaseUserAtom.onMount = (setAtom) => {
  const authStateChanged = async (authState) => {
    if (!authState) {
      setAtom(null);
      return;
    }

    setAtom(authState);
  };

  const unsubscribe = Firebase.auth().onAuthStateChanged(authStateChanged);

  return () => unsubscribe();
};

const userUpdateAtom = atom(0);

const authUserAtom = atom(async (get) => {
  // estabelece dependência entre este átomo e o userUpdateAtom:
  // quando o userUpdateAtom for atualizado, irá rodar novamente
  // esta função, chamando o /eu novamente
  get(userUpdateAtom);

  const authState = get(firebaseUserAtom);

  if (!authState) return null;

  const token = await authState.getIdToken();

  try {
    const { data: eu } = await axios.get('/api/v1/admin/eu', {
      baseURL: apiBaseUrl,
      headers: { Authorization: `Bearer ${token}` },
    });
    return formatAuthUser(authState, token, eu);
  } catch (err) {
    await handleLogout();
  }
});

const createAxiosInstanceAtom = atom((get) => {
  const authState = get(firebaseUserAtom);

  const tokenInterceptor = async (cfg) => {
    const token = await authState.getIdToken();
    if (authState) cfg.headers = { ...cfg.headers, Authorization: `Bearer ${token}` };
    return cfg;
  };

  const createAxiosInstance = (baseURL) => {
    const instance = axios.create({
      baseURL,
    });

    if (!tokenInterceptor) return null;

    instance.interceptors.request.use(tokenInterceptor);
    instance.interceptors.response.use((res) => res, permissionInterceptor);

    return instance;
  };

  return createAxiosInstance;
});

const apiProdutoraAtom = atom((get) => {
  const perfil = get(perfilAtom);
  const createAxiosInstance = get(createAxiosInstanceAtom);

  const baseUrl = `${apiBaseUrl}/api/v1/admin/produtora/${perfil.produtoraSelecionada?.id}`;
  const axiosProdutora = createAxiosInstance?.(baseUrl);

  return {
    baseUrl,
    axios: axiosProdutora,
    fetcher: (key) => axiosProdutora.get(key).then((res) => res.data),
  };
});

const apiAtom = atom((get) => {
  const createAxiosInstance = get(createAxiosInstanceAtom);
  return createAxiosInstance?.(apiBaseUrl);
});

const fetcherAtom = atom((get) => {
  const api = get(apiAtom);
  const fetcher = (...args) => api.get(...args).then(({ data }) => data);

  return fetcher;
});

const authUserLoadableAtom = loadable(authUserAtom);

const authLoadingAtom = atom((get) => {
  const { data, state } = get(authUserLoadableAtom);

  if (!data && state === 'hasData') {
    handleLogout();
  }

  return state !== 'hasData' || data === 'initial';
});

export default function useFirebaseAuth() {
  const { mutate } = useSWRConfig();
  const { data: authUser } = useAtomValue(authUserLoadableAtom);
  const authLoading = useAtomValue(authLoadingAtom);
  const apiProdutora = useAtomValue(apiProdutoraAtom);
  const api = useAtomValue(apiAtom);
  const fetcher = useAtomValue(fetcherAtom);
  const setUserUpdate = useSetAtom(userUpdateAtom);

  return {
    authUser,
    authLoading,
    signInWithGoogle,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateCurrentUserPassword,
    sendPasswordResetEmail,
    linkEmailProvider,
    unlinkAuthProvider,
    signOut,
    api,
    apiProdutora: useMemo(
      () => ({ ...apiProdutora, mutate: (key, ...args) => mutate(apiProdutora.baseUrl + key, ...args) }),
      [apiProdutora, mutate],
    ),
    fetcher,
    apiBaseUrl,
    refreshUser: () => setUserUpdate((u) => u + 1),
  };
}
