import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const Logo = styled((props) => {
  return (
    <svg width='42' height='32' viewBox='0 0 42 32' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.8,3.1c0-0.2,0.2-0.4,0.4-0.5c0.2-0.1,0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.9-0.2c0.3,0,0.6-0.1,0.9-0.1
        c0.2,0,0.4,0,0.7,0.1c0.2,0,0.3,0.2,0.3,0.5c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.4-0.5,1.1-0.8,2
        c-0.4,0.9-0.8,1.8-1.3,2.9c-0.5,1.1-1,2.2-1.5,3.4c-0.5,1.2-1,2.3-1.4,3.4c-0.5,1.2-0.9,2.3-1.2,3.3c-0.3,0.9-0.5,1.6-0.5,2
        c0,0.3,0.1,0.5,0.4,0.5c0.1,0,0.3-0.1,0.6-0.2c0.2-0.1,0.5-0.3,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.7c0.3-0.2,0.5-0.5,0.8-0.7
        c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.3-0.2,0.4-0.2c0.2,0,0.3,0.1,0.3,0.4c0,0.5-0.2,1.1-0.6,1.6c-0.4,0.6-0.9,1.1-1.5,1.5
        c-0.6,0.5-1.1,0.8-1.7,1.2c-0.6,0.3-1,0.5-1.4,0.6c-0.3,0.1-0.7,0.1-1,0.1c-0.6,0-1-0.2-1.3-0.5c-0.2-0.3-0.3-0.8-0.3-1.4
        c0-0.6,0.1-1.2,0.2-1.9c0.1-0.7,0.3-1.4,0.5-2.2c0.2-0.7,0.5-1.5,0.7-2.2c0.3-0.8,0.5-1.5,0.8-2.2c0.9-2.2,1.6-4.1,2.3-5.8
        C21.1,5.5,21.6,4.2,21.8,3.1z'
        fill='#0a1a2e'
      />
      <path
        opacity='1'
        d='M28.2,12.5c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.4,0.5-0.7,1
        c-0.3,0.6-0.7,1.2-1,1.9c-0.3,0.7-0.7,1.4-0.9,2.2c-0.3,0.7-0.4,1.4-0.4,1.9c0,0.3,0.1,0.5,0.4,0.5c0.1,0,0.3-0.1,0.6-0.2
        c0.2-0.1,0.5-0.3,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.7c0.3-0.2,0.5-0.5,0.8-0.7c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.3-0.2,0.4-0.2
        c0.2,0,0.3,0.1,0.3,0.4c0,0.1,0,0.3-0.1,0.4c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.3-0.3,0.4
        c-0.9,1-1.8,1.8-2.7,2.4c-0.9,0.5-1.7,0.8-2.6,0.8c-0.6,0-1-0.2-1.3-0.5c-0.2-0.3-0.3-0.8-0.3-1.4c0-0.6,0.1-1.2,0.2-2
        c0.1-0.7,0.3-1.5,0.6-2.3c0.2-0.8,0.5-1.5,0.8-2.2c0.3-0.7,0.6-1.3,1-1.7c0.1-0.1,0.3-0.3,0.6-0.4c0.3-0.1,0.6-0.2,0.9-0.3
        c0.3-0.1,0.7-0.1,1-0.2C27.7,12.5,28,12.5,28.2,12.5z'
        fill='#0a1a2e'
      />
      <circle cx='27.2' cy='10.2' r='1.8' fill='#E71D32' />
      <circle cx='30.9' cy='3.2' r='1.8' fill='#6FC393' />
      <rect x='29.4' y='5.8' width='3.5' height='3.5' fill='#74CDE0' />
      <polygon points='26.3,7.7 25.7,4.2 29.2,4.8 	' fill='#FDC412' />
      <path
        opacity='1'
        d='M19.4,5.6c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.6-0.5-1-0.7c-0.4-0.2-0.8-0.3-1.3-0.3c-0.5,0-1,0.1-1.5,0.3
        c-0.5,0.2-1,0.4-1.5,0.7c-0.5,0.3-0.9,0.6-1.4,1c-0.4,0.3-0.8,0.7-1.2,1c-0.3,0.3-0.6,0.6-0.9,0.8C9.8,7.5,9.6,7.7,9.5,7.8l0,0
        c0,0,0,0,0-0.1c0-0.3,0-0.5,0.1-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.7,0.3-1.2c0,0,0,0,0-0.1
        c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.3C9.8,3.4,9.7,3.4,9.5,3.3C9.4,3.2,9.3,3.2,9.2,3.2c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0
        C8.1,3.8,7.3,4.3,6.6,4.7C5.9,5.1,5.3,5.4,4.8,5.6C4.2,5.8,3.7,6,3.3,6C2.9,6.1,2.5,6.2,2.1,6.2c-0.1,0-0.2,0-0.3,0
        c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0.1-0.5,0.2C1,6.4,0.9,6.7,0.9,7.1c0,0.7,0.1,1.2,0.2,1.4c0.2,0.2,0.4,0.4,0.6,0.4
        c0.2,0,0.4,0,0.6,0c0.2,0,0.5-0.2,1-0.4c0.6-0.3,1-0.5,1.3-0.7c0.3-0.2,0.5-0.3,0.7-0.3c0.2,0,0.4,0.2,0.5,0.4C5.9,8.2,6,8.5,6,8.8
        c0,0.5,0,0.9-0.1,1.4c-0.1,0.5-0.2,0.9-0.2,1.1c-0.2,0.9-0.5,1.8-0.9,2.7C4.3,15.1,3.9,16,3.4,17c-0.5,0.9-0.9,1.8-1.2,2.7
        c-0.4,0.8-0.5,1.6-0.5,2.3c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,1,0.4c0.3,0,0.5-0.1,0.8-0.2c0.3-0.1,0.5-0.4,0.8-0.7
        c0.3-0.3,0.6-0.8,0.9-1.4c0.3-0.6,0.7-1.4,1-2.4c0.2-0.5,0.4-1.1,0.5-1.8c0.2-0.6,0.3-1.3,0.5-1.9c0.1-0.7,0.3-1.3,0.4-1.9
        c0.1-0.6,0.2-1.2,0.4-1.7c0-0.2,0.2-0.6,0.6-1.1C9.2,9.9,9.6,9.5,10.1,9c0.5-0.5,1-0.8,1.6-1.2c0.6-0.3,1.2-0.5,1.8-0.5
        c0.6,0,1,0.2,1.4,0.6c0.3,0.4,0.5,0.9,0.5,1.5c0,0.8-0.2,1.6-0.6,2.4c-0.4,0.8-0.8,1.5-1.4,2.1c-0.6,0.6-1.1,1.1-1.8,1.5
        c-0.6,0.4-1.2,0.6-1.6,0.6c-0.2,0-0.4,0-0.6-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0.1-0.6,0.4
        c-0.1,0.2-0.3,0.6-0.5,1c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.2,0.4,0.5,0.5c0.3,0.1,0.7,0.1,1.2,0.1
        c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6-0.1c0.7-0.1,1.4-0.4,2.1-0.8c0.7-0.4,1.4-0.9,2.1-1.5c0.7-0.6,1.3-1.3,1.9-2
        c0.6-0.7,1.1-1.5,1.6-2.3c0.4-0.8,0.8-1.6,1-2.4c0.3-0.8,0.4-1.6,0.4-2.3C19.7,6.4,19.6,6,19.4,5.6z'
        fill='#0a1a2e'
      />
      <path
        opacity='1'
        d='M41.8,17.9c-0.1,0-0.4,0.1-0.7,0.4c-0.3,0.3-0.7,0.6-1.1,0.9c-0.4,0.3-0.8,0.7-1.1,0.9
        c-0.4,0.3-0.7,0.4-0.9,0.4c-0.2,0-0.4-0.1-0.4-0.4c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.4c0.1-0.3,0.3-0.7,0.5-1
        c0.2-0.4,0.5-0.8,0.8-1.1c0.3-0.4,0.5-0.8,0.8-1.2c0.3-0.4,0.5-0.8,0.7-1.2c0.2-0.3,0.3-0.6,0.4-0.8c0.1-0.3,0.1-0.5,0.1-0.7
        c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.6-0.3-1.2-0.3c-0.5,0-0.9,0.1-1.5,0.3c-0.5,0.2-1,0.4-1.5,0.7c-0.5,0.3-1,0.6-1.5,1
        c-0.5,0.4-0.9,0.8-1.3,1.3c0.2-0.4,0.3-0.7,0.5-1c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.1-0.4,0.1-0.7
        c0-0.3-0.1-0.4-0.3-0.5c-0.2-0.1-0.4-0.1-0.7-0.1c-0.2,0-0.5,0-0.8,0.1c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.6,0.2-0.8,0.3
        c-0.2,0.1-0.4,0.3-0.4,0.5c-0.1,0.3-0.2,0.7-0.3,1c-0.1,0.4-0.2,0.8-0.4,1.2c-0.4,1.1-0.7,2-0.9,2.9c-0.2,0.8-0.4,1.5-0.6,2.1
        c-0.1,0.6-0.2,1.1-0.3,1.5c-0.1,0.4-0.1,0.7-0.1,1c0.4,0.3,0.8,0.4,1.1,0.4c0.5,0,1-0.3,1.3-0.8c0.4-0.5,0.7-1.2,1.1-2
        c0.3-0.8,0.7-1.5,1.2-2.1c0.4-0.6,0.9-1.1,1.3-1.5c0.5-0.4,0.9-0.7,1.3-1c0.4-0.2,0.7-0.3,0.9-0.3c0,0,0.1,0,0.1,0.1
        c0,0.1-0.1,0.2-0.2,0.4c-0.2,0.2-0.4,0.6-0.7,0.9c-0.3,0.4-0.6,0.8-0.8,1.3c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.5-0.3,1-0.3,1.5
        c0,0.6,0.1,1.1,0.3,1.4c0.2,0.4,0.7,0.6,1.3,0.6c0.8,0,1.7-0.3,2.5-0.8c0.9-0.5,1.8-1.3,2.7-2.4c0.2-0.2,0.4-0.5,0.6-0.9
        c0.2-0.4,0.3-0.7,0.3-1C42.1,18.1,42,17.9,41.8,17.9z'
        fill='#0a1a2e'
      />
    </svg>
  );
})``;
