import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useFirebaseAuth from './useFirebaseAuth';

const useRouteProtection = () => {
  const { authUser, authLoading } = useFirebaseAuth();
  const router = useRouter();

  useEffect(() => {
    if (!authLoading && !authUser) router.push('/login');
  }, [authLoading, authUser, router]);
};

export default useRouteProtection;
